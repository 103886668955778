import React from 'react'
import { Section } from './widgets'
import t from '../../i10n/translate'
import styled from 'styled-components'

const background7 = require('../../images/bg-7.png').default

const Section7 = props => {
  return (
    <Section background={background7}>
      <InnerContainer
        dangerouslySetInnerHTML={{ __html: t`s7.p` }}
      />
    </Section>
  )
}

const InnerContainer = styled.div`
  width: 880px;
  max-width: 95vw;
  white-space: break-spaces;

  p {
    text-align: justify;
  }
`

export default Section7
