import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 650px;
  padding: min(5%, 50px);
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-size: cover;
`

export const BigText = styled.h1`
  font-size: 55px;
  font-weight: 800;
  width: 100%;
  box-sizing: border-box;
  
  ${props => `
    text-align: ${props.center ? 'center' : 'left'};
    padding-left: ${props.indend ? 'min(100px, 10%)' : ''};
  `};

  em {
    color: var(--secondary-color);
    font-style: normal;
  }
`
