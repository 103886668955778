import React from 'react'
import styled from 'styled-components'

const AdvisorCard = props => {
  return (
    <Container>
      <Avatar src={props.image} alt={props.name} />
      <b>{props.name}</b>
      <Desc>{props.desc}</Desc>
    </Container>
  )
}

const Container = styled.div`
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  height: 420px;
  width: 380px;
  padding: 20px;
  margin-top: 100px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 60px;
  margin-top: 160px;
  font-size: 22px;

  b {
    width: 100%;
    text-align: left;
  }
  
  @media(max-width: 767px) {
    height: auto;
    min-width: 90vw;
  }
`

const Desc = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  @media(max-width: 767px) {
    padding-right: 0;
  }
`

const Avatar = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  top: -150px;
  
  
  @media(max-width: 767px) {
  }
`

export default AdvisorCard
