import styled from 'styled-components'

export const Button = styled.button`
  background-color: var(--secondary-color);
  padding: 12px 16px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
`
