import React from 'react'
import styled from 'styled-components'
import { Section } from './widgets'
import t from '../../i10n/translate'
import { Button } from '../widgets'

const background4 = require('../../images/bg-4.png').default

const Section4 = props => {
  return (
    <Container background={background4}>
      <InnerContainer>
        <p>{t`s4.p.1`}</p>
        <a href={t`sfk-link`}>
          <Button>{t`s4.btn.1`}</Button>
        </a>
        <p>{t`s4.p.2`}</p>
        <p>{t`s4.p.3`}</p>
      </InnerContainer>
      <InnerContainer>
        <p>{t`s4.p.4`}</p>
        <p>{t`s4.p.5`}</p>
        <p>{t`s4.p.6`}</p>
        <p>{t`s4.p.7`}</p>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Section)`  
  display: flex;  
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;

  p {
    text-align: justify;
    width: 100%;
  }

  button {
    width: 100%;
    height: 60px;
  }
`

export default Section4
