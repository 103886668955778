import React from 'react'
import styled from 'styled-components'
import { Section } from './widgets'
import t from '../../i10n/translate'

const background2 = require('../../images/bg-2.png').default

const Section1 = props => {
  return (
    <Container background={background2}>
      <InnerContainer>
        <h2>{t`s2.title`}</h2>
        <p>{t`s2.p.1`}</p>
        <p>{t`s2.p.2`}</p>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Section)`
  align-items: flex-start;
  justify-content: flex-start;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 880px;
  max-width: 90vw;
  margin: auto 0;
`

export default Section1
