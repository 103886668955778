import React from 'react'
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom'
import { getFileUrl } from '../data/api'
import useScript from '../hooks/useScript'
import Footer from './footer'
import Header from './header'
import Homepage from './homepage'
import ImprintScreen from './ImprintScreen'
import PrivacyPolicy from './PrivacyPolicy'
import ScrollToTop from './ScrollToTop'

const App = () => {
  const scriptStatus = useScript(getFileUrl('/gGmbH/translations/de-de.js'))
  if (scriptStatus !== 'ready') {
    return <div />
  }
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Header />
        <Switch>
          <Route path='/imprint'>
            <ImprintScreen />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/'>
            <Homepage />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  )
}
export default App
