import React from 'react'
import Link from '../Link'
import styled from 'styled-components'
import t from '../../i10n/translate'

const Footer = props => {
  return (
    <Container>
      <div className='footer-sections'>
        <Column>
          <Link smooth to={t`footer.link.1.href`}>{t`footer.link.1`}</Link>
          <Link smooth to={t`footer.link.2.href`}>{t`footer.link.2`}</Link>
          <Link smooth to={t`footer.link.3.href`}>{t`footer.link.3`}</Link>
          <Link smooth to={t`footer.link.4.href`}>{t`footer.link.4`}</Link>
          <Link smooth to={t`footer.link.5.href`}>{t`footer.link.5`}</Link>
          <Link smooth to={t`footer.link.6.href`}>{t`footer.link.6`}</Link>
          <Link smooth to={t`footer.link.7.href`}>{t`footer.link.7`}</Link>
        </Column>
        <div>
          <p>{t`text.address`}:</p>
          <div>{t`contact.address`}</div>
        </div>
        <Communication>
          {t`footer.communication`}
        </Communication>
        <div>
          {t`footer.text.1`}
          <div className='footer-goals'>
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-04.png' />
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-07.png' />
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-11.png' />
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/goal-13.png' />
          </div>
        </div>
      </div>
      <SpaceBetween style={{ marginTop: 20 }}>
        <SmLinks>
          <a rel='noopener noreferrer' target='_blank' href={t`sm.fb`}>
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/facebook.svg' />
          </a>
          <a rel='noopener noreferrer' target='_blank' href={t`sm.twitter`}>
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/twitter.svg' />
          </a>
          <a rel='noopener noreferrer' target='_blank' href={t`sm.insta`}>
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/instagram.svg' />
          </a>
          <a rel='noopener noreferrer' target='_blank' href={t`sm.linkedin`}>
            <img alt='' src='https://www.solarfuerkinder.de/cdn/images/footer/linkedin.svg' />
          </a>
        </SmLinks>
        <div>{t`copyright.text`}</div>
        <div>{t`footer.code`}</div>
      </SpaceBetween>
    </Container>
  )
}

const Container = styled.footer`
  background-color: var(--main-color);
  color: white;
  font-size: 18px;
  white-space: break-spaces;
  padding: 50px;
`

const SmLinks = styled.div`
  display: flex;
  img {
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }

  @media(max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`

const SpaceBetween = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
`

const Column = styled.p`
  display: flex;
  flex-direction: column;
`

const Communication = styled.p`
  white-space: brea-space;
`

export default Footer
