import React from 'react'
import styled from 'styled-components'
import { ReactFitty } from 'react-fitty'
import { Section } from './widgets'
import { Button } from '../widgets'
import t from '../../i10n/translate'

const background1 = require('../../images/bg-1.png').default

const Section1 = props => {
  return (
    <Section background={background1}>
      <InnerContainer>
        <ReactFitty>
          <H1 style={{ color: 'var(--main-color)' }}>{t`s1.big-text.1`}</H1>
          <H1 style={{ color: 'var(--secondary-color)' }}>{t`s1.big-text.2`}</H1>
        </ReactFitty>
        <Justify>{t`s1.p.text.1`}</Justify>
        <a href='#spenden'>
          <BigButton>{t`s1.btn.1`}</BigButton>
        </a>
      </InnerContainer>
    </Section>
  )
}

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  max-width: 95vw;
`

const H1 = styled.h1`
  width: 100%;
  text-align: left;
  margin: 0;
`

const Justify = styled.p`
  text-align: justify;
  font-weight: 400;
  margin: 50px 0px;
`

const BigButton = styled(Button)`
  font-size: 24px;
  width: 400px;
  max-width: 90vw;
`

export default Section1
