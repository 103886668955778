import React from 'react'
import styled from 'styled-components'
import t from '../../i10n/translate'
import { BigText } from './widgets'
import UserCard from './UserCard'

const TeamInfo = props => {
  return (
    <Container>
      <BigText style={{ textAlign: 'left' }}><em>{t`team.title`}</em></BigText>
      <CardContainer>
        <UserCard
          name={t`team.1.name`}
          image={t`team.1.image`}
          desc={t`team.1.desc`}
          link={t`team.1.link`}
        />
        <UserCard
          name={t`team.2.name`}
          image={t`team.2.image`}
          desc={t`team.2.desc`}
          link={t`team.2.link`}
        />
      </CardContainer>
      <p><b>{t`team.p.1`}</b></p>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px min(100px, 10%);
  background-color: white;
`

const CardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
`

export default TeamInfo
