import React from 'react'
import styled from 'styled-components'
import { Section } from './widgets'
import t from '../../i10n/translate'
import { Button } from '../widgets'

const logo = require('../../images/logo.svg').default
const background3 = require('../../images/bg-3.png').default

const Section1 = props => {
  return (
    <Container background={background3}>
      <Card>
        <Logo src={logo} />
        <p>{t`s3.card1.text`}</p>
        <Btn href='#wer-win-sind'>
          <Button>{t`s3.card1.btn`}</Button>
        </Btn>
      </Card>
      <Card2>
        <h1>{t`s3.card2.title`}</h1>
        <p>{t`s3.card2.text`}</p>
        <Btn href='#spenden'>
          <Button>{t`s3.card2.btn`}</Button>
        </Btn>
      </Card2>
    </Container>
  )
}

const Container = styled(Section)`
  flex-flow: row wrap;
`

const Card = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 510px;
  max-width: 90vw;
  padding: 70px 30px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Card2 = styled(Card)`
  background-color: var(--main-color);
  color: white;
`

const Btn = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 80%;
  }
`

const Logo = styled.img`

`

export default Section1
