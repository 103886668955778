import React from 'react';
import styled from 'styled-components';
import t from '../../i10n/translate';
import { BigText } from './widgets';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
// import Section6 from './Section6'
import Section7 from './Section7';
import TeamInfo from './TeamInfo';
import Advisors from './Avdisors';
import ImpressionsSection from './ImpressionsSection';

const Homepage = () => {
  return (
    <Container>
      <Section1 />
      <StatsContainer>
        <Stat>
          <div>{t`stat.1.value`}</div>
          <div>{t`stat.1.name`}</div>
        </Stat>
        <Stat>
          <div>{t`stat.2.value`}</div>
          <div>{t`stat.2.name`}</div>
        </Stat>
        <Stat>
          <div>{t`stat.3.value`}</div>
          <div>{t`stat.3.name`}</div>
        </Stat>
        <Stat>
          <div>{t`stat.4.value`}</div>
          <div>{t`stat.4.name`}</div>
        </Stat>
      </StatsContainer>
      <Section2 />
      <P>{t`homepage.text.1`}</P>
      <Section3 />
      <BigText id='wer-win-sind' center>
        {t`homepage.bigtxt.1.1`}
        <em>{t`homepage.bigtxt.1.2`}</em>
        {t`homepage.bigtxt.1.3`}
      </BigText>
      <Section4 />
      <TeamInfo />
      <Advisors />
      <BigText id='was-wir-machen' indend>
        {t`homepage.bigtxt.2.1`}
        <em>{t`homepage.bigtxt.2.2`}</em>
      </BigText>
      <Section5 />
      <BigText id='act-bas-lrn' indend>
        {t`homepage.bigtxt.3.1`}
        <em>{t`homepage.bigtxt.3.2`}</em>
      </BigText>
      {/* <Section6 />
      <BigText indend><em>{t`impressions.title`}</em></BigText> */}
      <ImpressionsSection />
      <BigText id='spenden' indend>
        <em>{t`homepage.bigtxt.4.1`}</em>
      </BigText>
      <Section7 />
    </Container>
  );
};

const Container = styled.div``;

const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 0px;
`;

const Stat = styled.div`
  text-align: center;
  margin: 0px 25px;

  > div:nth-child(1) {
    color: var(--secondary-color);
    font-weight: 800;
    font-size: 100px;
  }

  > div:nth-child(2) {
    white-space: break-spaces;
    font-weight: 800;
    size: 50px;
  }
`;

const P = styled.p`
  background: white;
  max-width: 1160px;
  font-weight: bold;
  margin: auto;
  padding: 20px;
`;

export default Homepage;
