import React from 'react'
import styled from 'styled-components'
import t from '../../i10n/translate'
import AdvisorCard from './AdvisorCard'
import { BigText } from './widgets'

const Advisors = props => {
  return (
    <Container>
      <BigText style={{ textAlign: 'left' }}><em>{t`advisors.title`}</em></BigText>
      <CardContainer>
        <AdvisorCard
          name={t`advisors.1.name`}
          desc={t`advisors.1.desc`}
          image={t`advisors.1.image`}
        />
        <AdvisorCard
          name={t`advisors.2.name`}
          desc={t`advisors.2.desc`}
          image={t`advisors.2.image`}
        />
        <AdvisorCard
          name={t`advisors.3.name`}
          desc={t`advisors.3.desc`}
          image={t`advisors.3.image`}
        />
        <AdvisorCard
          name={t`advisors.4.name`}
          desc={t`advisors.4.desc`}
          image={t`advisors.4.image`}
        />
      </CardContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px min(100px, 10%);
  background-color: white;
`

const CardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
`

export default Advisors
