import React from 'react'
import styled from 'styled-components'

const linkedin = require('../../images/ic_linkedin.svg').default

const UserCard = props => {
  return (
    <Container>
      <b>{props.name}</b>
      <Desc>{props.desc}</Desc>
      <Avatar src={props.image} alt={props.name} />
      <Icon href={props.link}>
        <img src={linkedin} alt='' />
      </Icon>
    </Container>
  )
}

const Container = styled.div`
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  height: 175px;
  width: 450px;
  max-width: 90vw;
  padding: 25px 20px 25px 100px;
  margin-left: 75px;
  position: relative;
  border-radius: 10px;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 20px;
  
  @media(max-width: 767px) {
    padding: 100px 20px 70px 25px;
    height: fit-content;
    margin-bottom: 30px;
    margin-left: 0;
    margin-top: 75px;
    text-align: center;
  }
`

const Desc = styled.div`
  padding-right: 40px;

  @media(max-width: 767px) {
    padding-right: 0;
  }
`

const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: -75px;
  transform: translateY(-50%);

  @media(max-width: 767px) {
    top: -75px;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Icon = styled.a`
  position: absolute;
  right: 20px;
  bottom: 15px;
  
  img {
    width: 48px;
    height: 48px;
  }

  @media(max-width: 767px) {
    right: unset;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }
`

export default UserCard
