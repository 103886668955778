import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getFileUrl } from '../data/api'

const PrivacyPolicy = props => {
  const [content, setContent] = useState('')

  useEffect(() => {
    window.fetch(getFileUrl('/gGmbH/privacy/privacy-policy.html'))
      .then(res => res.text())
      .then(setContent)
  }, [])

  return (
    <Container dangerouslySetInnerHTML={{ __html: content }} />
  )
}

const Container = styled.div`
  font-size: 18px;
  padding: 50px;
  min-height: 100vh;

  @media(max-width: 767px) {
    padding: 10px;
  }
`

export default PrivacyPolicy
