import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Link = props => {
  return isExternal(props.to)
    ? <a href={props.to} {...props} />
    : <HashLink {...props} />
}

const isExternal = url => /^https?:\/\//.test(url)

export default Link
