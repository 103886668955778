import React from 'react';
import Link from '../Link';
import styled from 'styled-components';
import t from '../../i10n/translate';
import { Button } from '../widgets';

const logo = require('../../images/logo.svg').default;
const deFlag = require('../../images/de_flag.svg').default;

const Header = (props) => {
  return (
    <Container>
      <Link to='/'>
        <Logo src={logo} />
      </Link>
      <SpaceEvenly>
        <Link smooth to='/#wer-win-sind'>{t`header.a.1.text`}</Link>
        <Link smooth to='/#was-wir-machen'>{t`header.a.2.text`}</Link>
        <Link smooth to='/#act-bas-lrn'>{t`header.a.3.text`}</Link>
        <Link smooth to={t`sfk-link`}>{t`header.a.4.text`}</Link>
      </SpaceEvenly>
      <Row>
        <Button as='a' href='/#spenden'>{t`header.btn`}</Button>
        <img src={deFlag} alt='Germany flag' />
        <b>DE</b>
      </Row>
    </Container>
  );
};

const Container = styled.header`
  display: flex;
  padding: 15px 30px;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  height: 40px;
  width: auto;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const SpaceEvenly = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;

  a {
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    a {
      margin-bottom: 10px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  * {
    margin-right: 10px;
  }

  *:first-child {
    margin-right: 20px;
  }
`;

export default Header;
