import React from 'react';
import styled from 'styled-components';
import { Section } from './widgets';
// import { Button } from '../widgets';
// import t from '../../i10n/translate';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const background1 = require('../../images/bg-1.png').default;

const images = [
  'https://picsum.photos/400/300?r=1',
  'https://picsum.photos/400/300?r=2',
  'https://picsum.photos/400/300?r=3',
  'https://picsum.photos/400/300?r=4',
  'https://picsum.photos/400/300?r=5'
];

const ImpressionsSection = (props) => {
  return (
    <Section background={background1}>
      <SlideContainer>
        <Slide
          easing='ease'
          slidesToShow={3}
          slidesToScroll={1}
          autoplay={false}
          indicators={true}
          cssClass='img-slide'
        >
          {images.map((image) => {
            return (
              <ImageContainer key={image}>
                <ImageSlide src={image} />
              </ImageContainer>
            );
          })}
        </Slide>
      </SlideContainer>
    </Section>
  );
};

const SlideContainer = styled.div`
  > * {
    max-width: 95vw;
  }
`;

const ImageContainer = styled.div`
  width: 400px;
  height: 400px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageSlide = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-right: 20px;
`;

export default ImpressionsSection;
