import React from 'react'
import styled from 'styled-components'
import { Section } from './widgets'
import t from '../../i10n/translate'
import { InnerContainer } from './Section4'

const background5 = require('../../images/bg-5.png').default

const Section5 = props => {
  return (
    <Container background={background5}>
      <InnerContainer>
        <p><b>{t`s5.p.1`}</b></p>
        <p><b>{t`s5.p.2`}</b></p>
        <p><b>{t`s5.p.3`}</b></p>
      </InnerContainer>
      <InnerContainer>
        <p>{t`s5.p.4`}</p>
        <p>{t`s5.p.5`}</p>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Section)`  
  display: flex;  
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;

  p {
    white-space: break-spaces;
  }
`

export default Section5
